/*
  ________ __________        .__               .__
 /  _____/ \______   \_______|__| _____ _____  |  |
/   __  \   |     ___/\_  __ \  |/     \\__  \ |  |
\  |__\  \  |    |     |  | \/  |  Y Y  \/ __ \|  |__
 \_____  /  |____|     |__|  |__|__|_|  (____  /____/
       \/                             \/     \/
.____    .__  _____        __________.__.__  .__
|    |   |__|/ ____\____   \______   \__|  | |  | _____ _______  ______
|    |   |  \   __\/ __ \   |     ___/  |  | |  | \__  \\_  __ \/  ___/
|    |___|  ||  | \  ___/   |    |   |  |  |_|  |__/ __ \|  | \/\___ \
|_______ \__||__|  \___  >  |____|   |__|____/____(____  /__|  /____  >
        \/             \/                              \/           \/
*/
/*///////////////////////////////////////////////
Google Fonts
//////////////////////////////////////////////*/
@import url(https://fonts.googleapis.com/css?family=Roboto|Work+Sans:700);
/*///////////////////////////////////////////////
Body
//////////////////////////////////////////////*/
ul {
  list-style: none;
  padding: 0;
}
p {
  line-height: 24px;
}
.loader {
  background-color: #ffffff;
}
/*///////////////////////////////////////////////
Fonts
//////////////////////////////////////////////*/
h1,
h2,
button,
h3 {
  font-family: 'Work Sans', sans-serif;
}
h1 {
  font-size: 3em;
}
h2 {
  font-size: 2.4em;
}
@media (max-width: 767px) {
  h2 {
    font-size: 1.6em;
  }
}
h3 {
  font-size: 1.8em;
}
body {
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
}
blockquote {
  font-size: 1em;
  padding: 0;
}
.center-img {
  margin: 0 auto;
}
/*///////////////////////////////////////////////
Header
//////////////////////////////////////////////*/
.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
}
.loader img {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -50px;
  margin-top: -50px;
}
header {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url('/assets/img/header-bg.jpg');
  background-size: cover;
  min-height: 600px;
}
.media-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.dark-overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  color: #fff;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
}
.dark-overlay .header-item {
  flex-wrap: wrap;
}
.dark-overlay .tagline {
  font-size: 1.4em;
  font-style: italic;
  font-weight: bold;
  margin: 40px 0;
}
.dark-overlay .button {
  padding: 10px;
  display: inline-block;
  text-decoration: none;
  border-radius: 4px;
  border: 1px solid #fff;
  color: #fff;
}
.dark-overlay .members-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  display: inline-block;
  text-decoration: none;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
}
@media (max-width: 767px) {
  .dark-overlay {
    padding: 20px 10px 20px 10px;
  }
  .dark-overlay .members-button {
    font-size: .7em;
    padding: 5px;
  }
}
.video-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
.video-bg > video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (min-aspect-ratio: 16/9) {
  .video-bg > video {
    height: 300%;
    top: -100%;
  }
}
@media (max-aspect-ratio: 16/9) {
  .video-bg > video {
    width: 300%;
    left: -100%;
  }
}
@supports (object-fit: cover) {
  .video-bg > video {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media (max-width: 1200px) {
  .video-bg {
    display: none;
  }
}
@media (max-width: 767px) {
  .media-container h1 {
    font-size: 1.5em;
  }
  .media-container h1 {
    font-size: 1.5em;
  }
}
/*///////////////////////////////////////////////
Main Section
//////////////////////////////////////////////*/
.backstretch {
  z-index: -9999;
}
.main-section {
  padding: 150px 0 0;
}
.main-section h1 {
  color: #ffffff;
  font-size: 2em;
}
@media only screen and (min-width: 768px) {
  .main-section h1 {
    font-size: 3em;
  }
}
@media only screen and (min-width: 1200px) {
  .main-section h1 {
    font-size: 4em;
  }
}
.main-section p {
  color: #ffffff;
  padding: 12px 0 60px;
}
/*///////////////////////////////////////////////
Subscribe Section
//////////////////////////////////////////////*/
.subscribe-form {
  padding: 0px 0 30px;
  display: block;
}
.subscribe-form h2 {
  padding: 24px 0 0;
  color: #ffffff;
}
.subscribe-form p {
  color: #fff;
  opacity: .6;
  font-size: 14px;
}
.subscribe-form form {
  padding: 45px 0 0;
  border-radius: 6px;
}
.subscribe-form form input {
  background-color: #222!important;
}
.subscribe-form form input.sub-name {
  background: url('/assets/img/sub-name.png') no-repeat;
  background-position: 12px 14px;
}
.subscribe-form form input.sub-mail {
  background: url('/assets/img/sub-mail.png') no-repeat;
  background-position: 12px 15px;
}
.subscribe-form form input.form-control {
  height: 50px;
  width: 236px;
  border: 0;
  margin: 0 12px 0 0;
  border-radius: 6px!important;
  padding: 0 0 0 40px;
}
.subscribe-form button {
  height: 52px;
  line-height: 52px;
  padding: 0 40px 0;
  border-radius: 6px!important;
  border: 0;
  border-bottom: 5px solid #efefef;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  white-space: normal;
}
.subscribe-form button:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  border-bottom: 5px solid #dedfe0;
}
.subscribe-form button i {
  margin: 16px 0 0 10px;
  float: right;
  height: 18px;
  width: 18px;
  display: block;
  line-height: 18.5px!important;
  border-radius: 40px!important;
  color: #ffffff;
}
.subscribe-form button:hover i {
  background-color: #ededed;
  transition: all .5s;
}
/*///////////////////////////////////////////////
Features Section
//////////////////////////////////////////////*/
.features {
  padding: 30px 0 30px 0;
}
.features .container:before {
  margin-top: 40px;
}
.box {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 10px 25px;
  text-align: right;
  display: block;
  margin: 40px 0 40px 0;
}
@media only screen and (min-width: 768px) {
  .box {
    border-radius: 3px;
    margin: 40px 0 40px 0;
    min-height: 265px;
    border: 1px solid #ddd;
  }
}
.box-icon {
  border-radius: 50%;
  display: table;
  height: 100px;
  margin: 0 auto;
  width: 100px;
  margin-top: -61px;
}
.box-icon.red {
  background-color: #F44336;
}
.box-icon.green {
  background-color: #4CAF50;
}
.box-icon.blue {
  background-color: #3949AB;
}
.box-icon.yellow {
  background-color: #FFEB3B;
}
.box-icon.orange {
  background-color: #FB8C00;
}
.box-icon.purple {
  background-color: #880E4F;
}
.box-icon span {
  color: #fff;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
.info h4 {
  letter-spacing: 2px;
  text-transform: uppercase;
}
.info > p {
  color: #717171;
  padding-top: 10px;
  text-align: justify;
}
.info > a {
  background-color: #03a9f4;
  border-radius: 2px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
  transition: all 0.5s ease 0s;
}
.info > a:hover {
  background-color: #0288d1;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
}
/*///////////////////////////////////////////////
Extras Section
//////////////////////////////////////////////*/
#extras {
  padding: 30px 0 0 0;
  background-color: rgba(240, 243, 246, 0.9);
}
.fixed-bg {
  background: url('/assets/img/bg2.png') no-repeat;
  background-attachment: fixed;
  background-size: auto;
}
.extras-txt h3 {
  color: #313131;
  padding: 30px 0 0;
}
.extras-txt p {
  color: #949799;
  line-height: 22px;
  padding: 30px 0 0;
}
.why-join ul {
  margin: 40px 0 40px 0;
  padding: 0;
}
.why-join ul li {
  color: #949799;
  margin: 0 0 14px;
}
.why-join ul li span {
  padding: 0 10px 0 0;
  margin-top: 3px;
}
.title2 {
  padding: 70px 0 70px 0;
}
.title2 span {
  color: #d9e0e7;
  float: left;
}
.title2 p {
  color: #949799;
  float: none;
  clear: both;
  margin: 30px 0 30px 0;
}
.title2 span.divi {
  height: 6px;
  width: 110px;
  border-radius: 6px;
  display: block;
  margin: 0px 0 0;
}
#faqs {
  padding: 0 0 70px;
}
.panel-default > .panel-heading {
  background-color: #f8f8f8;
}
#accordion h3 {
  color: #747474;
  height: 52px;
  width: 100%;
  border: 1px solid #e8e8e8;
  line-height: 52px;
  margin: 15px 0 0 0;
  border-radius: 6px;
  background-color: #f0f3f6;
}
#accordion h3.ui-state-active {
  color: #000000;
  background-color: #ffffff;
}
#accordion h3 span {
  color: #a3a3a3;
  width: 50px;
  display: block;
  text-align: center;
  line-height: 52px;
  float: left;
}
#accordion div p {
  color: #949799;
  padding: 25px 20px 30px 0;
  display: block;
  margin: 0;
  clear: both;
  line-height: 24px;
  width: 360px;
}
#accordion div {
  clear: both;
}
#accordion h3 {
  outline: none;
}
#accordion h3:hover {
  cursor: pointer;
}
/*///////////////////////////////////////////////
Testimonials
//////////////////////////////////////////////*/
.quote {
  color: rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}
.carousel-inner {
  padding: 20px;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 5px;
}
#fade-quote-carousel.carousel {
  padding-bottom: 60px;
}
#fade-quote-carousel.carousel .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}
#fade-quote-carousel.carousel .carousel-inner .active {
  opacity: 1;
  transition-property: opacity;
}
#fade-quote-carousel.carousel .carousel-indicators > li {
  background-color: #2193cd;
  border: none;
}
#fade-quote-carousel blockquote {
  border: none;
}
#fade-quote-carousel .profile-circle {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  border-radius: 100px;
}
/*///////////////////////////////////////////////
Extras 2
//////////////////////////////////////////////*/
#extras2 {
  display: block;
  background-color: rgba(240, 243, 246, 0.9);
}
.extras2-bg {
  height: 576px;
  display: block;
}
#extras2 .about-txt h3 {
  padding: 40px 0 40px;
}
#extras2 .about-img {
  padding: 0 0 20px 0;
}
#extras2 .about-img img {
  max-width: 100%;
}
#extras2 .about-txt p {
  color: #949799;
}
/*///////////////////////////////////////////////
CTA
//////////////////////////////////////////////*/
.cta {
  display: block;
}
.cta-bg {
  display: block;
  background-color: #0C4863;
  padding: 50px 0 50px;
  height: 100%;
  width: 100%;
}
.cta-bg .pricing-info {
  color: #fff;
  padding-top: 10px;
  font-style: italic;
}
.cta .title {
  color: #fff;
}
.cta .container {
  opacity: 1!important;
}
.cta h3 {
  color: #ffffff;
  text-align: center;
}
.cta p {
  max-width: 700px;
  text-align: center;
  margin: 0 auto;
  color: #ffffff;
  opacity: 0.5;
  line-height: 28px;
  padding: 25px 0 30px;
}
.signup-button {
  height: 52px;
  line-height: 52px;
  padding: 0 40px 0;
  border-radius: 6px!important;
  border: 0;
  border-bottom: 5px solid #efefef;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}
.signup-button:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-bottom: 5px solid #dedfe0;
}
.signup-button i {
  background-color: #0C4863;
  margin: 12px 0 0 10px;
  float: right;
  height: 24px;
  width: 24px;
  display: block;
  line-height: 24.5px!important;
  border-radius: 40px!important;
  color: #ffffff;
}
.signup-button:focus {
  outline: 0;
}
.signup-button-header {
  margin-top: 18px;
}
/*///////////////////////////////////////////////
Footer
//////////////////////////////////////////////*/
footer {
  height: 100px;
  display: block;
  background-color: #ffffff;
  padding: 40px 0 40px 0;
  font-size: .7em;
}
footer .footer-logo {
  margin: 0 auto;
}
